import ContentCard from '../../ContentCard/ContentCard';
import React, { useMemo } from 'react';
import {
  datetimeWrapper,
  simpleArrayWrapper,
} from '../../../DataTransformFactory';
import { useTranslation } from 'react-i18next';
import Formatter from '../../../FormatFactory';
import Reducers from '../../../ReducerFactory';
import StatsCard from '../../ContentCard/StatsCard';
import ChartCard from '../../ContentCard/ChartCard';
import { useUser } from '../../../providers';

export const Operations = (props) => {
  const user = useUser();
  const { t } = useTranslation('translation');

  const stats = useMemo(() => {
    const stat = {
      title: t('views.operations.total'),
      items: [
        {
          title: 'views.operations.shipped_in_time_proportion',
          key: 'shipped_in_time_proportion',
          reducer: Reducers.total,
          formatter: Formatter.percent,
        },
        {
          title: 'views.operations.avg_shipping_latency',
          key: 'shipping_latency',
          reducer: Reducers.total,
          formatter: Formatter.secondsToDays,
        },
      ],
      api: {
        module: 'analytics',
        path: 'shipped_in_time/v1',
        data_wrapper_opts: {
          key: 'shipped_in_time_proportion',
          data_key: 'data',
        },
        data_wrapper: simpleArrayWrapper,
      },
      alias: 'shipped_in_time_proportion',
      mergeData: [
        {
          api: {
            module: 'analytics',
            path: 'shipping_latency/v1',
            data_wrapper: simpleArrayWrapper,
            data_wrapper_opts: {
              key: 'avg_latency_in_seconds',
              data_key: 'data',
            },
            queryparams: {
              days_spanned: 7,
            },
          },
          alias: 'shipping_latency',
        },
      ],
      height: 120,
      component: StatsCard,
    };

    if (user?.return_orders_enabled) {
      stat.items.push({
        title: 'views.operations.return_ratio',
        key: 'return_ratio',
        reducer: Reducers.total,
        formatter: Formatter.percent,
      });
      stat.mergeData.push({
        api: {
          module: 'analytics',
          path: 'orders_return_ratio/v1',
          data_wrapper: simpleArrayWrapper,
          data_wrapper_opts: {
            key: 'ratio',
            data_key: 'data',
          },
        },
        alias: 'return_ratio',
      });
    }
    return stat;
  }, [user]);

  const daily_shipped_in_time = {
    xtype: 'datetime',
    title: t('views.operations.daily_shipped_in_time'),
    labels: {
      index: t('charts.units.date'),
      value: t('charts.units.percent'),
    },
    formatter: Formatter.percent,
    api: {
      module: 'analytics',
      path: 'daily_shipped_in_time/v1',
      data_wrapper_opts: {
        x: 'start_date',
        y: 'in_time_proportion',
        data_key: 'data',
      },
      data_wrapper: datetimeWrapper,
    },
    type: 'line',
    orientation: 'vertical',
    height: 400,
    component: ChartCard,
  };

  const daily_shipping_latency = {
    xtype: 'datetime',
    title: t('views.operations.daily_shipping_latency'),
    labels: {
      index: t('charts.units.date'),
      value: t('charts.units.days'),
    },
    formatter: Formatter.secondsToDays,
    api: {
      module: 'analytics',
      path: 'daily_shipping_latency/v1',
      data_wrapper_opts: {
        x: 'end_date',
        y: 'average_latency_in_seconds',
        data_key: 'data',
      },
      queryparams: {
        days_spanned: 7,
      },
      data_wrapper: datetimeWrapper,
    },
    type: 'line',
    orientation: 'vertical',
    height: 400,
    component: ChartCard,
  };

  const daily_orders_return_ratio = {
    xtype: 'datetime',
    title: t('views.operations.daily_orders_return_ratio'),
    labels: {
      index: t('charts.units.date'),
      value: t('charts.units.percent'),
    },
    formatter: Formatter.percent,
    api: {
      module: 'analytics',
      path: 'daily_orders_return_ratio/v1',
      data_wrapper_opts: {
        x: 'start_date',
        y: 'ratio',
        data_key: 'data',
      },
      data_wrapper: datetimeWrapper,
    },
    type: 'line',
    orientation: 'vertical',
    height: 400,
    component: ChartCard,
  };

  return (
    <>
      <div className="column is-full" key="operation_stats">
        <ContentCard {...props} {...stats} />
        <ContentCard {...props} {...daily_shipped_in_time} />
        <ContentCard {...props} {...daily_shipping_latency} />
        {user.return_orders_enabled ? (
          <ContentCard {...props} {...daily_orders_return_ratio} />
        ) : null}
      </div>
    </>
  );
};
