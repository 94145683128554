import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import Revenue from './components/ViewComponents/Revenue/Revenue';
import Orders from './components/ViewComponents/Orders/Orders';
import Dashboard from './components/ViewComponents/Dashboard/Dashboard';
import Performances from './components/ViewComponents/Performances/Performances';
import PerformancesProduct from './components/ViewComponents/Performances/PerformancesProduct';
import PerformancesOrigin from './components/ViewComponents/Performances/PerformancesOrigin';
import PerformancesSource from './components/ViewComponents/Performances/PerformancesSource';
import Shipping from './components/ViewComponents/Shipping/Shipping';
import ShippingCarrier from './components/ViewComponents/Shipping/ShippingCarrier';
import Offers from './components/ViewComponents/Offers/Offers';
import Offer from './components/ViewComponents/Offers/Offer';
import OffersSource from './components/ViewComponents/Offers/OffersSource';
import NoAccess from './components/ViewComponents/Access/NoAccess';

import routesHidden from './components/FilterBar/routesHidden';
import { useUser } from './providers';
import { Page404 } from './components/ViewComponents/404';
import { Operations } from './components/ViewComponents/Operations/Operations';

export default function ContentPane(props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const user = useUser();

  if (queryParams.get('skipped_login')) {
    return <Redirect to="/no-access" />;
  } else {
    return (
      <div
        className={
          routesHidden.indexOf(location.pathname) !== -1
            ? 'ContentPane columns fullheight'
            : 'ContentPane columns'
        }
      >
        <Switch>
          <Route exact path="/">
            <Redirect to="/dashboard" />
          </Route>
          <Route exact path="/orders">
            <Orders {...props} />
          </Route>
          <Route exact path="/operations">
            <Operations {...props} />
          </Route>
          <Route exact path="/dashboard">
            <Dashboard {...props} />
          </Route>

          <Route exact path="/revenue">
            {user.service_credentials_enabled !== false ? (
              <Revenue {...props} />
            ) : (
              <Redirect to={'no-access'} />
            )}
          </Route>

          <Route exact path="/performances">
            <Performances {...props} />
          </Route>
          <Route exact path="/performances/product/:product">
            <PerformancesProduct {...props} />
          </Route>
          <Route exact path="/performances/origin/:origin">
            <PerformancesOrigin {...props} />
          </Route>
          <Route exact path="/performances/source/:source">
            <PerformancesSource {...props} />
          </Route>
          <Route exact path="/shipping">
            <Shipping {...props} />
          </Route>
          <Route path="/shipping/details/:carrier">
            <ShippingCarrier {...props} />
          </Route>
          <Route exact path="/offers">
            <Offers {...props} />
          </Route>
          <Route exact path="/offers/source/:source">
            <OffersSource {...props} />
          </Route>
          <Route exact path="/offers/:offer">
            <Offer {...props} />
          </Route>
          <Route exact path="/no-access">
            <NoAccess {...props} />
          </Route>
          <Route path={'*'}>
            <Page404 />
          </Route>
        </Switch>
      </div>
    );
  }
}
