import React, { useEffect, useState } from 'react';

import Chart from 'react-apexcharts';

import { useTranslation } from 'react-i18next';

import fr from 'apexcharts/dist/locales/fr.json';
import en from 'apexcharts/dist/locales/en.json';
import es from 'apexcharts/dist/locales/es.json';
import sanitizeSeries from '../../sanitizeSeries.js';

import Helpers from './SBIChartHelpers';

export default function DatetimeChart(props) {
  const { i18n } = useTranslation('translation');

  const [state, setState] = useState({
    options: {
      chart: {
        id: props.title,
        zoom: {
          enabled: false,
        },
        locales: [fr, en, es],
        defaultLocale: i18n.language.substring(0, 2),
        toolbar: {
          tools: {
            download: "<img src='/assets/icons/download.svg' width='16px' />",
          },
          export: {
            csv: {
              columnDelimiter: Helpers.csvSeparator(i18n.language),
            },
          },
        },
      },
      stroke: {
        width: 2,
      },
      xaxis: {
        type: 'datetime',
        tooltip: {
          enabled: false,
        },
      },
      tooltip: {
        x: {
          format: 'dddd dd MMMM',
        },
      },
    },
    series: [],
  });

  // Refresh when props are changing (i.e change in filters)
  useEffect(() => {
    setState((prev) => ({
      options: {
        chart: {
          ...prev.options.chart,
        },
        stroke: {
          width: 2,
        },
        xaxis: {
          type: 'datetime',
          tooltip: {
            enabled: false,
          },
        },
        tooltip: {
          x: {
            format: 'dddd dd MMMM',
          },
        },
      },
      series: sanitizeSeries([
        {
          name: props.labels.value,
          data: props.data,
        },
      ]),
    }));
  }, [props]);

  function formattedSeries() {
    return state.series.map((series) => {
      return {
        ...series,
        data: series.data.map(([date, value]) => [
          date,
          props.formatter(value),
        ]),
      };
    });
  }

  return (
    <Chart
      options={state.options}
      series={formattedSeries()}
      type={props.type}
      width="100%"
      height={props.height}
    />
  );
}
